import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import _get from 'lodash/get';
import _some from 'lodash/some';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import DEFAULT_BRANCH_DATES from '@lumirental/lumi-web-sdk/dist/constants/date/DEFAULT_BRANCH_DATES';
import SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SCREENS_NAME';
import SEARCH_DEBOUNCE_TIME from '@lumirental/lumi-web-shared/lib/constants/app/SEARCH_DEBOUNCE_TIME';
import CAR_DELIVERY_DURATION from '@lumirental/lumi-web-shared/lib/constants/date/CAR_DELIVERY_DURATION';
import DATE_TIME_PICKER from '@lumirental/lumi-web-shared/lib/constants/date/DATE_TIME_PICKER';
import DAYS_30 from '@lumirental/lumi-web-shared/lib/constants/date/DAYS_30';
import TAB_INDEX from '@lumirental/lumi-web-shared/lib/constants/form/TAB_INDEX';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';
import useAB from '@lumirental/lumi-web-shared/lib/hooks/useAB';
import useSearchPageProps from '@lumirental/lumi-web-shared/lib/hooks/useSearchPageProps';
import getLocaleDayMonth from '@lumirental/lumi-web-shared/lib/utils/date/getLocaleDayMonth';
import getNextWorkingDay from '@lumirental/lumi-web-shared/lib/utils/date/getNextWorkingDay';
import dateSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/dateSelected';
import longTermTapped from '@lumirental/lumi-web-shared/lib/utils/gtm/longTermTapped';
import timeSelected from '@lumirental/lumi-web-shared/lib/utils/gtm/timeSelected';
import { validateDateAndTimeSearch } from '@lumirental/lumi-web-shared/lib/utils/validateSearch';

import { DropoffChargesInfo } from '@lumirental/lumi-web-components-ui';

import {
  DARK_CLASS,
  DROPOFF_DATE,
  DROPOFF_TIME,
  PICKUP_DATE,
  PICKUP_TIME,
} from '../../constants';
import usePickUpLocationProps from '../../hooks/usePickUpLocationProps';
import DropOffBranchPicker from '../BranchPicker/DropOffBranchPicker';
import PickUpBranchPicker from '../BranchPicker/PickUpBranchPicker';
import DatePicker from '../DatePicker';
import DifferentCityReturnDialog from '../Dialogs/DifferentCityReturn';
import FreeCarDeliveryDialog from '../Dialogs/FreeCarDelivery';
import FindBranchTab from '../Tabs/FindBranchTab';
import FindCarTab from '../Tabs/FindCar';
import TimePicker from '../TimePicker';

import styles from './FindBranchBlock.style';

const useStyles = makeStyles()(styles);

const defaultDateAndTimeError = {
  [PICKUP_DATE]: false,
  [PICKUP_TIME]: false,
  [DROPOFF_DATE]: false,
  [DROPOFF_TIME]: false,
};

export default function FindBranchBlock({
  store,
  textInputRef,
  onSearchClick,
  showCloseBtn,
  handleCloseSearch,
  isThemeChange,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const pathName = usePathname();

  const pickupDatePickerRef = useRef(null);
  const dropoffDatePickerRef = useRef(null);

  // adding a local state because of a switch issue
  // switch does not turn on when coming from listing page
  const [localSwitchState, setlocalSwitchState] = useState(false);
  const [branchUpdated, setBranchUpdated] = useState(false);
  const [dateClicked, setDateClicked] = useState(false);
  const [openFreeCarDeliveryDialog, setOpenFreeCarDeliveryDialog] =
    useState(false);

  // state for snackbar message to show tab is changed
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState(t('dropoff_date_updated'));
  const [dateAndTimeError, setDateAndTimeError] = useState(
    defaultDateAndTimeError,
  );

  const { language } = useLanguageContext();

  // read props for csr
  const {
    activeTabIndex,
    setActiveTabIndex,
    setSwitchState,
    isSwitchOn,
    isDeliveryTabActive,
    pickupBranch,
    dropoffBranch,
    pickupDate,
    dropoffDate,
    pickupTime,
    dropoffTime,
    setPickUpTime,
    setDropOffTime,
    isSameDay,
    setDropOffDate,
    diffInDays,
    isPickUpBranchSelected,
    isDropOffBranchSelected,
    openPickupTimer,
    openDropoffTimer,
    setOpenPickupTimer,
    setOpenDropoffTimer,
    setPickUpBranch,
    setDropOffBranch,
    minDaysOfDeliveryDropOff,
    setActiveListBy,
    pickUpCount,
    carDeliveryCount,
    setMinDeliveryDays,
  } = useSearchPageProps(store);

  const { clearLocationProps } = usePickUpLocationProps(store);

  const isDeliveryFeatureFlag = useAB('free_car_delivery_tag');
  const isMinimumDeliveryFeatureFlag = useAB('minimum_car_delivery_tag');
  const isDropOffChargesFlagEnable = useAB('drop_off_charges_flag');
  const isCarDelivery = isDeliveryFeatureFlag && isDeliveryTabActive;

  const pickupBranchName = _get(pickupBranch, 'name', '');
  const dropoffBranchName = _get(dropoffBranch, 'name', '');
  const pickupCityId = _get(pickupBranch, 'cityId', -1);
  const dropoffCityId = _get(dropoffBranch, 'cityId', -1);

  const formattedPickupDate =
    pickupDate && getLocaleDayMonth(pickupDate, language);
  const formattedDropoffDate =
    dropoffDate && getLocaleDayMonth(dropoffDate, language);

  const pickupOnDays = _get(pickupBranch, 'onDays', {});
  const dropoffOnDays = _get(dropoffBranch, 'onDays', {});
  const pickupOffDays = _get(pickupBranch, 'offDays', []);
  const dropoffOffDays = _get(dropoffBranch, 'offDays', []);
  const pickupTiming = _get(pickupBranch, 'timingSummary', []);
  const dropoffTiming = _get(dropoffBranch, 'timingSummary', []);
  const leadTimeMinutes = _get(
    pickupBranch,
    'leadTimeMinutes',
    DATE_TIME_PICKER.T_PLUS_240_M,
  );

  const searchBtnDisabled =
    !isPickUpBranchSelected || (isSwitchOn && !isDropOffBranchSelected);

  const wrapperClass = `${classes.wrapper} ${isThemeChange ? DARK_CLASS : ''}`;

  const isListingScreen = showCloseBtn;

  /**
   * set active list when feature flag enable
   */
  useEffect(() => {
    if (isDeliveryFeatureFlag) {
      setActiveListBy && setActiveListBy('listByTab');
    }
  }, [isDeliveryFeatureFlag, setActiveListBy]);

  /**
   * set minimum delivery days when feature flag enable
   */
  useEffect(() => {
    if (isDeliveryFeatureFlag && setMinDeliveryDays) {
      if (isMinimumDeliveryFeatureFlag) {
        setMinDeliveryDays(CAR_DELIVERY_DURATION.DAYS_15);
      } else {
        setMinDeliveryDays(CAR_DELIVERY_DURATION.DAYS_30);
      }
    }
  }, [isDeliveryFeatureFlag, isMinimumDeliveryFeatureFlag, setMinDeliveryDays]);

  /**
   * effect to update tab index when date selected from date picker
   * does not call when tab is clicked directly
   * does not call when delivery feature flag is true
   */
  useEffect(() => {
    if (!isDeliveryFeatureFlag) {
      if (setActiveTabIndex && dateClicked) {
        if (diffInDays < DAYS_30) {
          setActiveTabIndex(TAB_INDEX.FIRST); // update store
        } else {
          setActiveTabIndex(TAB_INDEX.SECOND); // update store
        }
      }
    }
  }, [diffInDays, dateClicked, setActiveTabIndex, isDeliveryFeatureFlag]);

  /**
   * effect to persist the switch state
   */
  useEffect(() => {
    setlocalSwitchState(isSwitchOn); // update local state
  }, [isSwitchOn]);

  /**
   * Handles Date Update
   * @param {object}  dateChanged
   * @param {boolean} dateChanged.isPickUpDateChanged
   * @param {boolean} dateChanged.isDropOffDateChanged
   */
  const handleDateUpdate = ({ isPickUpDateChanged, isDropOffDateChanged }) => {
    setBranchUpdated(!branchUpdated);
  };

  /**
   * Handles switch toggle
   * @param {object} evt
   */
  const handleToggleSwitch = (evt) => {
    const { checked } = evt.target;
    setlocalSwitchState(checked); // update local state
    setSwitchState(checked); // update global store
  };

  /**
   * Handles tab click
   * @param {number} tabIndex
   */
  const handleTabClick = (tabIndex) => {
    let newDropOffDate = null;
    if (tabIndex === TAB_INDEX.FIRST) {
      newDropOffDate = addDays(pickupDate, 1); // add 1 day
    } else {
      newDropOffDate = addMonths(pickupDate, 1); // add 1 month
      // send GTM event when long term tab is clicked
      const screenName = SCREENS_NAME[pathName];
      longTermTapped(screenName);
    }

    const offDays = _get(dropoffBranch, 'offDays', []); // get dropoff off days
    const nextWorkingDay = getNextWorkingDay(newDropOffDate, offDays);

    setDropOffDate(nextWorkingDay); // update global store
    setActiveTabIndex(tabIndex); // update global store
    setDateClicked(false); // update local state
    setSnackBarMsg(t('dropoff_date_updated'));
    setOpenSnackBar(true); // update local state
  };

  const handlePickupDateClick = (pickupDate) => {
    // send GTM event when date is selected
    dateSelected(PICKUP_DATE, pickupDate);

    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [PICKUP_DATE]: false,
    });

    handleDateClick();
    setOpenPickupTimer(true);
  };

  const handleDropoffDateClick = (dropoffDate) => {
    // send GTM event when date is selected
    dateSelected(DROPOFF_DATE, dropoffDate);

    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [DROPOFF_DATE]: false,
    });

    handleDateClick();
    setOpenDropoffTimer(true);
  };

  /**
   * Callback function for date picker
   */
  const handleDateClick = () => {
    setDateClicked(true); // update local state
  };

  const handleSnackBarClose = (evt, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleSelectPickupTime = (selectedTime) => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [PICKUP_TIME]: false,
    });

    // open date picker
    handleOpenDatePicker();

    setPickUpTime(selectedTime); // update global store
    closePickupTimer();

    // send GTM event when pickup time is selected
    timeSelected(PICKUP_TIME, selectedTime);
  };

  const handleSelectDropoffTime = (selectedTime) => {
    // update local state
    setDateAndTimeError({
      ...dateAndTimeError,
      [DROPOFF_TIME]: false,
    });

    setDropOffTime(selectedTime); // update global store
    closeDropoffTimer();

    // send GTM event when dropoff time is selected
    timeSelected(DROPOFF_TIME, selectedTime);
  };

  const closePickupTimer = () => {
    setOpenPickupTimer(false); // update local state
  };

  const closeDropoffTimer = () => {
    setOpenDropoffTimer(false); // update local state
  };

  const tabChanged = (tabIndx) => {
    if (tabIndx !== activeTabIndex) {
      setActiveTabIndex(tabIndx);
      setPickUpBranch({});
      setDropOffBranch({});
      setSwitchState(false); // update global store
      clearLocationProps(); // clear location store
      setlocalSwitchState(false);
    }
  };

  const handelSearch = () => {
    handleOpenFreeCarDeliveryDialog();
  };

  /**
   * Open free car delivery dialog
   */
  const handleOpenFreeCarDeliveryDialog = () => {
    setOpenFreeCarDeliveryDialog(true);
  };

  /**
   * Close free car delivery dialog
   */
  const handleCloseFreeCarDeliveryDialog = () => {
    setOpenFreeCarDeliveryDialog(false);
    handleSearchClick && handleSearchClick();
  };

  const handleSearchClick = () => {
    const {
      isPickupBranchClosed,
      isDropoffBranchClosed,
      isPickupTimeValid,
      isDropoffTimeValid,
      isMinDurationValid,
      isTPlusLeadTimeValid,
    } = validateDateAndTimeSearch({
      pickupBranch,
      dropoffBranch,
      pickupDate,
      dropoffDate,
      pickupTime,
      dropoffTime,
      isSameDay,
    });

    const dateAndTimeErrObj = {
      [PICKUP_DATE]: isPickupBranchClosed,
      [PICKUP_TIME]: !isPickupTimeValid || !isTPlusLeadTimeValid,
      [DROPOFF_DATE]: isDropoffBranchClosed,
      [DROPOFF_TIME]: !isDropoffTimeValid || !isMinDurationValid,
    };

    setDateAndTimeError(dateAndTimeErrObj);

    const hasError = _some(dateAndTimeErrObj);

    if (hasError) {
      // add fake delay if date/time is invalid
      setTimeout(() => {
        onSearchClick && onSearchClick();
      }, SEARCH_DEBOUNCE_TIME);
    } else {
      onSearchClick && onSearchClick();
    }
  };

  const handleOpenDatePicker = () => {
    if (isPickUpBranchSelected) {
      // open dropoff date picker dialog.
      dropoffDatePickerRef?.current?.click();
    }
  };

  return (
    <>
      <div className={wrapperClass}>
        {isDeliveryFeatureFlag ? (
          <FindBranchTab
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            setActiveBranchTab={tabChanged}
            pickUpBranchCount={pickUpCount}
            deliveryBranchCount={carDeliveryCount}
            pickupDate={pickupDate}
            dropoffBranch={dropoffBranch}
            minDaysOfDeliveryDropOff={minDaysOfDeliveryDropOff}
            setDropOffDate={setDropOffDate}
            isPickUpBranchSelected={isPickUpBranchSelected}
            showCloseBtn={showCloseBtn}
            handleCloseSearch={handleCloseSearch}
          />
        ) : (
          <FindCarTab
            activeTabIndex={activeTabIndex}
            handleActiveTab={handleTabClick}
            showCloseBtn={showCloseBtn}
            handleCloseSearch={handleCloseSearch}
          />
        )}
        <div className={classes.searchFormWrapper}>
          <Grid container className={classes.formSection}>
            <Grid item xs={12} className={classes.fieldRow}>
              <PickUpBranchPicker
                store={store}
                textInputRef={textInputRef}
                id="pickupLocation"
                isSwitchOpen={isSwitchOn}
                defaultValue={pickupBranchName}
                dropoffCityId={dropoffCityId}
                isDropOffBranchSelected={isDropOffBranchSelected}
                isListingScreen={isListingScreen}
              />
              {/* show dropoff location field when switch is on */}
              {isSwitchOn && (
                <DropOffBranchPicker
                  store={store}
                  id="dropoffLocation"
                  isSwitchOpen={isSwitchOn}
                  defaultValue={dropoffBranchName}
                  pickupCityId={pickupCityId}
                  isPickUpBranchSelected={isPickUpBranchSelected}
                />
              )}
            </Grid>
            <Grid item xs={12} className={classes.fieldRow}>
              <DatePicker
                store={store}
                id={PICKUP_DATE}
                value={formattedPickupDate}
                handleDateClick={handlePickupDateClick}
                isThemeChange={isThemeChange}
                handleDateUpdate={handleDateUpdate}
                forwardedRef={pickupDatePickerRef}
                isError={dateAndTimeError[PICKUP_DATE]}
              />
              <TimePicker
                id={PICKUP_TIME}
                selectedBranch={pickupBranch}
                value={pickupTime}
                onDays={pickupOnDays}
                offDays={pickupOffDays}
                selectedDate={pickupDate || DEFAULT_BRANCH_DATES.PICKUP}
                selectedTime={pickupTime}
                isSameDay={isSameDay}
                isBranchSelected={isPickUpBranchSelected}
                handleSelectTime={handleSelectPickupTime}
                openTimer={openPickupTimer}
                outsideCloseTimer={closePickupTimer}
                isThemeChange={isThemeChange}
                branchTiming={pickupTiming}
                leadTimeMinutes={leadTimeMinutes}
                branchUpdated={branchUpdated}
                isError={dateAndTimeError[PICKUP_TIME]}
              />

              <DatePicker
                store={store}
                id={DROPOFF_DATE}
                value={formattedDropoffDate}
                handleDateClick={handleDropoffDateClick}
                isThemeChange={isThemeChange}
                forwardedRef={dropoffDatePickerRef}
                isError={dateAndTimeError[DROPOFF_DATE]}
              />
              <TimePicker
                id={DROPOFF_TIME}
                selectedBranch={dropoffBranch}
                value={dropoffTime}
                onDays={dropoffOnDays}
                offDays={dropoffOffDays}
                selectedDate={dropoffDate || DEFAULT_BRANCH_DATES.DROPOFF}
                selectedTime={dropoffTime}
                pickupTime={pickupTime}
                isSameDay={isSameDay}
                isBranchSelected={isDropOffBranchSelected}
                handleSelectTime={handleSelectDropoffTime}
                openTimer={openDropoffTimer}
                outsideCloseTimer={closeDropoffTimer}
                isThemeChange={isThemeChange}
                branchTiming={dropoffTiming}
                branchUpdated={branchUpdated}
                isError={dateAndTimeError[DROPOFF_TIME]}
              />
            </Grid>

            <Grid
              item
              xs={12}
              className={(classes.fieldRow, classes.switchWrapper)}
            >
              <div className={classes.switchSection}>
                {!isCarDelivery && (
                  <>
                    <Switch
                      checked={localSwitchState}
                      onChange={handleToggleSwitch}
                      name="checkedA"
                      classes={{
                        thumb: classes.switchThumb,
                        track: classes.switchTrack,
                      }}
                      data-testid="searchSwitch"
                      inputProps={{
                        'aria-label': t('return_to_a_different_location'),
                      }}
                    />
                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        variant="subtitle1"
                        className={classes.switchLabel}
                      >
                        {t('return_to_a_different_location')}
                      </Typography>
                      {isDropOffChargesFlagEnable && (
                        <DropoffChargesInfo isSearchFunnel={!isListingScreen} />
                      )}
                    </Box>
                  </>
                )}
              </div>

              <div className={classes.btnWrapper}>
                <Button
                  disabled={searchBtnDisabled}
                  fullWidth
                  variant="contained"
                  onClick={isCarDelivery ? handelSearch : handleSearchClick}
                  data-testid="searchButton"
                >
                  {t('search')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <DifferentCityReturnDialog
        open={false}
        onProceed={() => {}}
        onClose={() => {}}
      />
      <FreeCarDeliveryDialog
        openDialog={openFreeCarDeliveryDialog}
        handleCloseDialog={handleCloseFreeCarDeliveryDialog}
      />
      <Snackbar
        classes={{
          root: classes.snackBarRoot,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={2000}
        message={snackBarMsg}
        data-testid="searchFindTabSnackbarError"
      />
    </>
  );
}
